import {Component, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

declare const $: any;

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
  public languages = {
    en: 'English',
    fr: 'Français',
    hi: 'हिंदी',
    'zh-cn': '中文 – 简体',
    'zh-tw': '中文 – 繁體',
  };
  public selectedLang;
  public opened = false;
  public projectShow = false;

  constructor(public translate: TranslateService) {
  }

  ngOnInit() {
    this.selectedLang = 'en';
    this.translate.use('en');
  }

  setLanguage(code) {
    this.translate.use(code);
    this.selectedLang = code;
  }
}
