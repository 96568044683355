<div class="relative bg-gray-800">
  <div class="max-w-7xl mx-auto px-4 sm:px-6">
    <div class="flex justify-between items-center py-6 md:justify-start md:space-x-10">
      <div class="lg:w-0 lg:flex-1">
        <a routerLink="/" class="flex">
          <img class="h-40" src="../../../assets/img/objex-logo-transparentbg2.png" alt="Objex Logo">
          <!-- <svg class="h-8 py-1 text-white" viewBox="0 0 121 20">
            <path fill="currentColor"
                  d="M22.38 2.4v14.31c0 1.513-.747 2.27-2.24 2.27H2.22C.74 18.98 0 18.223 0 16.71V2.4C0 .893.74.14 2.22.14h17.92c1.493 0 2.24.753 2.24 2.26zM4.48 14.47H17.9v-9.8H4.48v9.8zm43.517-2.66v4.9c0 1.513-.747 2.27-2.24 2.27h-20.14V.14h19.97c1.493 0 2.24.753 2.24 2.26v4.9c0 1.14-.69 1.87-2.07 2.19 1.493.333 2.24 1.107 2.24 2.32zm-17.9-4.17h13.42V4.67h-13.42v2.97zm0 6.83h13.42v-2.99h-13.42v2.99zM68.233 0c.6 0 1.123.19 1.57.57.447.38.67.927.67 1.64v14.5c0 1.513-.747 2.27-2.24 2.27h-15.57c-.707 0-1.26-.22-1.66-.66-.407-.44-.61-.977-.61-1.61 0-.613.203-1.14.61-1.58.4-.44.953-.66 1.66-.66h13.36V2.21c0-.713.22-1.26.66-1.64.433-.38.95-.57 1.55-.57zm25.621 18.98h-17.92c-1.48 0-2.22-.757-2.22-2.27V2.4c0-1.507.74-2.26 2.22-2.26h17.83c.713 0 1.27.223 1.67.67.4.447.6.977.6 1.59 0 .62-.2 1.153-.6 1.6-.4.447-.957.67-1.67.67h-15.59V7.3h15.34c.707 0 1.257.227 1.65.68.393.447.59.977.59 1.59 0 .62-.197 1.147-.59 1.58-.393.44-.943.66-1.65.66h-15.34v2.66h15.68c.707 0 1.257.22 1.65.66.393.44.59.967.59 1.58 0 .633-.197 1.17-.59 1.61-.393.44-.943.66-1.65.66zM118.57 0c.634 0 1.174.233 1.62.7.454.467.68 1.017.68 1.65 0 .673-.3 1.233-.9 1.68l-7.17 5.6 7.2 5.43c.594.487.89 1.067.89 1.74 0 .313-.06.61-.18.89s-.283.527-.49.74c-.206.22-.45.393-.73.52-.28.133-.566.2-.86.2-.32 0-.583-.043-.79-.13-.206-.08-.41-.207-.61-.38l-7.73-6.35-7.73 6.35c-.226.187-.463.32-.71.4-.253.073-.483.11-.69.11-.3 0-.583-.067-.85-.2a2.327 2.327 0 01-.72-.52c-.206-.213-.37-.46-.49-.74-.12-.28-.18-.577-.18-.89 0-.32.067-.623.2-.91.127-.293.35-.57.67-.83l7.19-5.6-7.16-5.4c-.3-.207-.52-.447-.66-.72-.14-.267-.21-.597-.21-.99 0-.3.06-.59.18-.87s.287-.533.5-.76c.22-.22.464-.397.73-.53.274-.127.56-.19.86-.19.48 0 .937.177 1.37.53l7.7 6.35 7.7-6.41c.187-.147.397-.257.63-.33.234-.08.48-.127.74-.14z"/>
          </svg> -->
        </a>
      </div>
      <div class="hidden space-x-10 md:flex">
        <a href="#services" class="font-medium text-white hover:text-gray-300 transition duration-150 ease-in-out">
          {{ 'header.services' |  translate }}
        </a>
        <!-- <a href="#team" class="font-medium text-white hover:text-gray-300 transition duration-150 ease-in-out">
          {{ 'header.team' |  translate }}
        </a> -->
        <a href="#contact-us" class="font-medium text-white hover:text-gray-300 transition duration-150 ease-in-out">
          {{ 'header.contact-us' |  translate }}
        </a>
        <a href="https://objex.blog/" class="font-medium text-white hover:text-gray-300 transition duration-150 ease-in-out">
          {{ 'header.blog' |  translate }}
        </a>
          <div class="relative">
            <button (click)="projectShow = !projectShow" type="button" class="group rounded-md text-white inline-flex items-center text-base font-medium hover:text-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
              <span>{{ 'header.products' |  translate }}</span>
              <svg class="ml-2 h-5 w-5 text-gray-400 group-hover:text-gray-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd" />
              </svg>
            </button>
            <div *ngIf="projectShow" class="absolute left-1/2 transform -translate-x-1/2 mt-3 px-2 w-screen max-w-sm sm:px-0 z-10">
              <div class="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
                <div class="z-20 relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8">
                  <a href="https://rabbito.social" class="-m-3 p-3 flex items-center rounded-lg hover:bg-gray-50">
                    <img src="https://rabbito.social/assets/logos/logo-small.png" alt="" class="flex-shrink-0 h-8 w-auto">
                    <div class="ml-4">
                      <p class="text-base font-medium text-gray-900">
                        Rabbito Social
                      </p>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        <a href="https://objex.careers/" class="font-medium text-white hover:text-gray-300 transition duration-150 ease-in-out">
          {{ 'header.careers' |  translate }}
        </a>

      </div>
      <div class="flex items-center justify-end space-x-3 flex-1 lg:w-0">
        <button [matMenuTriggerFor]="langMenu" class="py-3 flex items-center focus:outline-none text-white transition duration-100 ease-in">
          <span>{{languages[selectedLang]}}</span>
          <svg class="ml-2 w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7"></path>
          </svg>
        </button>

        <mat-menu #langMenu="matMenu" xPosition="before">
          <button mat-menu-item *ngFor="let lang of languages | keyvalue" (click)="setLanguage(lang.key)">
            {{lang.value}}
          </button>
        </mat-menu>

        <button (click)="opened = true" type="button" class="md:hidden inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:bg-gray-700 focus:outline-none focus:bg-gray-100 focus:text-gray-500 transition duration-150 ease-in-out">
          <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16"></path>
          </svg>
        </button>
      </div>
    </div>
  </div>

  <div *ngIf="opened" class="z-10 absolute top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden">
    <div class="rounded-lg shadow-lg">
      <div class="rounded-lg shadow-xs bg-white divide-y-2 divide-gray-50">
        <div class="pt-5 pb-6 px-5 space-y-6">
          <div class="flex items-center justify-between">
            <div>
              <svg class="h-6 py-1 w-auto text-gray-800" viewBox="0 0 121 20">
                <path fill="currentColor"
                      d="M22.38 2.4v14.31c0 1.513-.747 2.27-2.24 2.27H2.22C.74 18.98 0 18.223 0 16.71V2.4C0 .893.74.14 2.22.14h17.92c1.493 0 2.24.753 2.24 2.26zM4.48 14.47H17.9v-9.8H4.48v9.8zm43.517-2.66v4.9c0 1.513-.747 2.27-2.24 2.27h-20.14V.14h19.97c1.493 0 2.24.753 2.24 2.26v4.9c0 1.14-.69 1.87-2.07 2.19 1.493.333 2.24 1.107 2.24 2.32zm-17.9-4.17h13.42V4.67h-13.42v2.97zm0 6.83h13.42v-2.99h-13.42v2.99zM68.233 0c.6 0 1.123.19 1.57.57.447.38.67.927.67 1.64v14.5c0 1.513-.747 2.27-2.24 2.27h-15.57c-.707 0-1.26-.22-1.66-.66-.407-.44-.61-.977-.61-1.61 0-.613.203-1.14.61-1.58.4-.44.953-.66 1.66-.66h13.36V2.21c0-.713.22-1.26.66-1.64.433-.38.95-.57 1.55-.57zm25.621 18.98h-17.92c-1.48 0-2.22-.757-2.22-2.27V2.4c0-1.507.74-2.26 2.22-2.26h17.83c.713 0 1.27.223 1.67.67.4.447.6.977.6 1.59 0 .62-.2 1.153-.6 1.6-.4.447-.957.67-1.67.67h-15.59V7.3h15.34c.707 0 1.257.227 1.65.68.393.447.59.977.59 1.59 0 .62-.197 1.147-.59 1.58-.393.44-.943.66-1.65.66h-15.34v2.66h15.68c.707 0 1.257.22 1.65.66.393.44.59.967.59 1.58 0 .633-.197 1.17-.59 1.61-.393.44-.943.66-1.65.66zM118.57 0c.634 0 1.174.233 1.62.7.454.467.68 1.017.68 1.65 0 .673-.3 1.233-.9 1.68l-7.17 5.6 7.2 5.43c.594.487.89 1.067.89 1.74 0 .313-.06.61-.18.89s-.283.527-.49.74c-.206.22-.45.393-.73.52-.28.133-.566.2-.86.2-.32 0-.583-.043-.79-.13-.206-.08-.41-.207-.61-.38l-7.73-6.35-7.73 6.35c-.226.187-.463.32-.71.4-.253.073-.483.11-.69.11-.3 0-.583-.067-.85-.2a2.327 2.327 0 01-.72-.52c-.206-.213-.37-.46-.49-.74-.12-.28-.18-.577-.18-.89 0-.32.067-.623.2-.91.127-.293.35-.57.67-.83l7.19-5.6-7.16-5.4c-.3-.207-.52-.447-.66-.72-.14-.267-.21-.597-.21-.99 0-.3.06-.59.18-.87s.287-.533.5-.76c.22-.22.464-.397.73-.53.274-.127.56-.19.86-.19.48 0 .937.177 1.37.53l7.7 6.35 7.7-6.41c.187-.147.397-.257.63-.33.234-.08.48-.127.74-.14z"/>
              </svg>
            </div>
            <div class="-mr-2">
              <button (click)="opened = false" type="button" class="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-500 transition duration-150 ease-in-out">
                <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path>
                </svg>
              </button>
            </div>
          </div>
          <div>
            <nav class="grid gap-y-8">
              <a href="#services" class="-m-3 p-3 flex items-center rounded-md hover:bg-gray-50 transition ease-in-out duration-150">
                <div class="text-base leading-6 font-medium text-gray-900">
                  {{ 'header.services' |  translate }}
                </div>
              </a>
              <a href="#team" class="-m-3 p-3 flex items-center rounded-md hover:bg-gray-50 transition ease-in-out duration-150">
                <div class="text-base leading-6 font-medium text-gray-900">
                  {{ 'header.team' |  translate }}
                </div>
              </a>
              <a href="#contact-us" class="-m-3 p-3 flex items-center rounded-md hover:bg-gray-50 transition ease-in-out duration-150">
                <div class="text-base leading-6 font-medium text-gray-900">
                  {{ 'header.contact-us' |  translate }}
                </div>
              </a>
              <a href="https://objex.cloud/" class="-m-3 p-3 flex items-center rounded-md hover:bg-gray-50 transition ease-in-out duration-150">
                <div class="text-base leading-6 font-medium text-gray-900">
                  {{ 'header.blog' |  translate }}
                </div>
              </a>
              <a href="https://objex.careers/" class="-m-3 p-3 flex items-center rounded-md hover:bg-gray-50 transition ease-in-out duration-150">
                <div class="text-base leading-6 font-medium text-gray-900">
                  {{ 'header.careers' |  translate }}
                </div>
              </a>
            </nav>

            <div class="border-t border-gray-350 mt-8 pt-4">
              <h3 class="text-sm font-medium tracking-wide text-gray-500 uppercase mb-4">
                Projects
              </h3>
              <a href="https://rabbito.social" class="-m-3 p-3 flex items-center rounded-lg hover:bg-gray-50">
                <div class="flex-shrink-0 flex items-center justify-center h-10 w-10 rounded-md bg-white text-white ml-4">
                  <img src="https://rabbito.social/assets/logos/logo-small.png" alt="" class="flex-shrink-0 h-8 w-auto">
                </div>
                <div class="ml-4 text-base font-medium text-gray-900">
                  Rabbito Social
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
