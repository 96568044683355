<div class="bg-gray-800" id="microservices">

  <!-- Jumbotron -->
  <div
  class="relative overflow-hidden rounded-lg bg-cover bg-no-repeat p-12 text-center" style="background-image: url('assets/banners/apis.jpg'); height: 400px">
  <div
    class="absolute bottom-0 left-0 right-0 top-0 h-full w-full overflow-hidden bg-fixed"
    style="background-color: rgba(0, 0, 0, 0.6)">
    <div class="flex h-full items-center justify-center">
      <div class="text-white">
        <h2 class="mb-4 text-6xl font-extrabold tracking-tight"><span class="text-yellow-500">APIs</span> Architecture Consulting</h2>
        <h4 class="mb-6 text-xl font-semibold">Unlocking the Power of Seamless Integration</h4>
      </div>
    </div>
  </div>

  </div>
  <!-- Jumbotron -->

  <div class="mx-auto py-12 px-4 max-w-screen-xl sm:px-6 lg:px-8 lg:py-24">
    <div class="space-y-12">

      <p class="text-white">
        <span class="text-yellow-400">Streamlined Integration with Apigee:</span>
Objex's API Architecture Service leverages the power of Apigee, a leading API management platform, to streamline your integration processes. Our team of experts will work closely with you to design, develop, and deploy APIs that connect your applications, services, and data sources. With Apigee, we ensure your APIs are secure, scalable, and performant, enabling you to accelerate digital transformation and deliver exceptional user experiences.
      </p>

      <p class="text-white">
        <span class="text-yellow-400">In today's digital era,</span> organizations are striving to enhance their digital capabilities and improve customer experiences. Efficient and secure integration of applications and systems has become paramount for success. That's where Objex's API Architecture Service comes in, offering unrivaled expertise in Apigee, AWS API Gateway, and Azure API Gateway to empower our customers with seamless integration solutions. 
      </p>
      
      <p class="text-white">
        <span class="text-yellow-400">Scalable Integration with AWS API Gateway:</span>
Objex's API Architecture Service harnesses the capabilities of AWS API Gateway, a fully managed service, to provide scalable and reliable integration solutions. Our seasoned professionals will collaborate with you to architect and implement APIs that seamlessly connect your systems, microservices, and serverless functions. By leveraging AWS API Gateway, we enable you to unlock the power of the AWS ecosystem and efficiently manage your APIs with features such as caching, throttling, and request/response transformations.
      </p>      

      <p class="text-white">
        <span class="text-yellow-400">Efficient Integration with Azure API Gateway:</span>
Objex's API Architecture Service also specializes in leveraging Azure API Gateway, a fully managed service on the Microsoft Azure platform. Our team will assist you in designing and deploying APIs that integrate your applications, services, and data with ease. With Azure API Gateway, we empower you to optimize performance, secure your APIs with authentication and authorization mechanisms, and monitor and manage your APIs effectively through Azure's comprehensive suite of management tools.
      </p>  
      <!-- <div class="bg-cover" style="background-image: url('assets/banners/microservices.webp'); height: 50%"></div> -->

      <h2 class="text-yellow-400 text-4xl ">Why Choose Objex's API Architecture Service?</h2>
      <ul class="mb-8 space-y-4 list-decimal text-left text-gray-300 dark:text-gray-300">
        <li class="flex items-center space-x-3">
          <!-- Icon -->
          <svg class="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path></svg>
          <span>Expertise in Leading API Gateway Solutions: Our team has extensive knowledge and experience working with Apigee, AWS API Gateway, and Azure API Gateway, ensuring that we can tailor the solution to meet your specific requirements.</span>
        </li>
        <li class="flex items-center space-x-3">
          <!-- Icon -->
          <svg class="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path></svg>
          <span>Customized Integration Solutions: We understand that every organization has unique integration needs. Our experts will work closely with you to design and implement API architectures that align with your business objectives, technology stack, and scalability requirements. </span>
        </li>     
        <li class="flex items-center space-x-3">
          <!-- Icon -->
          <svg class="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path></svg>
          <span>Security and Performance Focus: We prioritize the security and performance of your APIs. By leveraging industry best practices and utilizing the advanced features of Apigee, AWS API Gateway, and Azure API Gateway, we ensure that your APIs are protected from vulnerabilities and deliver optimal performance. </span>
        </li>    
        <li class="flex items-center space-x-3">
          <!-- Icon -->
          <svg class="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path></svg>
          <span>End-to-End Support: From the initial design phase to deployment and ongoing maintenance, Objex provides comprehensive support throughout the API architecture lifecycle. Our team will assist you in troubleshooting, scaling, and optimizing your APIs to ensure they continue to meet your evolving needs. </span>
        </li>    
       
      </ul>
   
    </div>
    <br/>
    <div class="">
    <p class="text-yellow-400 text-4xl text-xl leading-7">Unlock the true potential of seamless integration with Objex's API Architecture Service. Contact us today to discover how we can empower your organization with efficient, secure, and scalable API solutions leveraging Apigee, AWS API Gateway, and Azure API Gateway. Let us help you drive digital transformation and deliver exceptional experiences to your customers. </p>
    </div>
  </div>
</div>
