import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-teams',
  templateUrl: './teams.component.html',
  styleUrls: ['./teams.component.scss']
})
export class TeamsComponent implements OnInit {
  title = 'Objex Team';

  public team = [
    {
      name: 'Mazhar Choudhry',
      image: 'assets/img/faces/mazhar.jpeg',
      position: 'CEO',
      linkedIn: 'https://www.linkedin.com/in/mazchoudhry/',
      twitter: 'https://twitter.com/mazrnow'
    },
    {
      name: 'Nauman Umer',
      image: 'assets/img/faces/placeholder.jpg',
      position: 'Sofware Engineer',
    },
    // {
    //   name: 'Arslan Ahmed',
    //   image: 'assets/img/faces/arslan.jpg',
    //   position: 'Operations',
    // },
    {
      name: 'Timi',
      image: 'assets/img/faces/timi.jpg',
      position: 'Software Engineer',
    },
    {
      name: 'Justin St-Louis Wood',
      image: 'assets/img/faces/justin.png',
      position: 'Marketing Engineer'
    },
    {
      name: 'Nauria',
      image: 'assets/img/faces/placeholder.jpg',
      position: 'UI/UX Engineer'
    },
    {
      name: 'Kawsar',
      image: 'assets/img/faces/placeholder.jpg',
      position: 'Software Engineer',
    },
    {
      name: 'Abdoola',
      image: 'assets/img/faces/placeholder.jpg',
      position: 'UX Designer',
    },
    {
      name: 'Dr. Lutfi',
      image: 'assets/img/faces/placeholder.jpg',
      position: 'QA',
    },
    {
      name: 'Jamal Choudhry',
      image: 'assets/img/faces/placeholder.jpg',
      position: 'Intern',
    }    
  ];

  constructor(private titleService: Title, private metaService: Meta) { }

  ngOnInit() {
    this.titleService.setTitle(this.title);
    this.metaService.addTags([
      {name: 'keywords', content: 'Kubernetes, Cloud Native, APIs, Apigee, Microservices, Monitoring, Google Cloud Partner'},
      {name: 'description', content: 'Objex Team Page'},
      {name: 'robots', content: 'index, follow'}
    ]);
  }
}
