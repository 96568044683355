<div class="background-img" style="background-image: url('./assets/img/sections/the-how-photographer.jpg')">
    <div class="filter"></div>
    <div class="container">
        <div class="row">
            <h1 class="title"> &nbsp;500 <br/>
                <p class="error-msg">We're sorry, but something went wrong. We are working an fixing this. <br/> Please refresh the page in a couple of seconds.</p>
            </h1>
        </div>
    </div>
</div>
<div class="container-cards">
    <div class="row">
        <h5 class="discover-pages text-center">You can discover:</h5>
        <br/><br/><br/>
    </div>

    <div class="row">
        <div class="container">
            <div class="row">
                <div class="col-md-4">
                    <div class="card card-just-text">
                        <div class="card-body text-center">
                            <div class="card-icon"><a href="/#services"><i class="fa fa-desktop" aria-hidden="true"></i></a></div>
                            <p class="card-description">Services</p>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="card card-hover-effect card-just-text">
                        <div class="card-body text-center">
                            <h4 class="card-icon"><a href="/#portfolio"><i class="fa fa-scissors" aria-hidden="true"></i></a></h4>
                            <p class="card-description">Portfolio</p>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="card card-hover-effect card-just-text">
                        <div class="card-body text-center">
                            <h4 class="card-icon"><a href="/#contact-us"><i class="fa fa-gift" aria-hidden="true"></i></a></h4>
                            <p class="card-description">Contact Us</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>