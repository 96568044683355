import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-apis',
  templateUrl: './apis.component.html',
  styleUrls: ['./apis.component.scss']
})
export class ApisComponent implements OnInit {
  title = 'APIs';


  constructor(private titleService: Title, private metaService: Meta) { }

  ngOnInit() {
    this.titleService.setTitle(this.title);
    this.metaService.addTags([
      {name: 'keywords', content: 'Kubernetes, Cloud Native, APIs, Apigee, Microservices, Monitoring, Google Cloud Partner, AWS, Azure'},
      {name: 'description', content: 'Objex APIs Service'},
      {name: 'robots', content: 'index, follow'}
    ]);
  }
}
