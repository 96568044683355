import { Component, OnInit, HostListener } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Title, Meta } from '@angular/platform-browser';
/*
    Gateway to objex.tech website
    - it renders all the needed sections for landing page
*/
@Component({
    selector: 'app-sections',
    templateUrl: './sections.component.html',
    styleUrls: ['./sections.component.scss']
})
export class SectionsComponent implements OnInit {
    title = 'Objex, Inc';
    private fragment: string;
    data: Date = new Date();

    constructor(router: Router, private titleService: Title, private metaService: Meta) {
        router.events.subscribe(s => {
            if (s instanceof NavigationEnd) {
                const tree = router.parseUrl(router.url);
                if (tree.fragment) {
                    const element = document.querySelector('#' + tree.fragment);
                    if (element) { element.scrollIntoView(); }
                }
            }
        });
    }

    ngOnInit() {
        this.titleService.setTitle(this.title);
        this.metaService.addTags([
          {name: 'keywords', content: 'Objex, Objex Inc, Objex Inc., Cloud Native, APIs, Apigee, Microservices, ' +
              'Monitoring, GCP, Azure, AWS'},
          {name: 'description', content: 'Cloud Solutions Provider'},
          {name: 'robots', content: 'index, follow'}
        ]);
    }

}
