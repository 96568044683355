import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-microservices',
  templateUrl: './microservices.component.html',
  styleUrls: ['./microservices.component.scss']
})
export class MicroservicesComponent implements OnInit {
  title = 'Microservices';

  constructor(private titleService: Title, private metaService: Meta) { }

  ngOnInit() {
    this.titleService.setTitle(this.title);
    this.metaService.addTags([
      {name: 'keywords', content: 'Kubernetes, Cloud Native, APIs, Apigee, Microservices, Monitoring, Google Cloud Partner'},
      {name: 'description', content: 'Objex is proud to present our Microservices Architecture Service, designed to revolutionize the way you develop and deploy applications. With our expertise, we empower you to build resilient and scalable systems that can adapt to your evolving business needs and velocity.'},
      {name: 'robots', content: 'index, follow'}
    ]);
  }
}
