import {Component, OnInit} from '@angular/core';
import {DomSanitizer, Meta, Title} from '@angular/platform-browser';

@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.scss']
})
export class ServicesComponent implements OnInit {
  title = 'Objex Services';
  services = [];

  constructor(
    private titleService: Title,
    private metaService: Meta,
    private sanitizer: DomSanitizer,
  ) {
  }

  ngOnInit() {
    this.titleService.setTitle(this.title);
    this.metaService.addTags([
      {name: 'keywords', content: 'Kubernetes, Cloud Native, APIs, Apigee, Microservices, Monitoring, Google Cloud Partner'},
      {name: 'description', content: 'Objex provides Cloud Native services.'},
      {name: 'robots', content: 'index, follow'}
    ]);

    this.services = [
      {
        link: 'https://cloud.objex.tech/infrastructure',
        icon: '<svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 12a9 9 0 01-9 9m9-9a9 9 0 00-9-9m9 9H3m9 9a9 9 0 01-9-9m9 9c1.657 0 3-4.03 3-9s-1.343-9-3-9m0 18c-1.657 0-3-4.03-3-9s1.343-9 3-9m-9 9a9 9 0 019-9"></path></svg>',
      },
      {
        link: '/microservices',
        icon: 'assets/svg/microservices.svg',
      },
      {
        link: 'https://cloud.objex.tech/api',
        icon: '<svg class="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M6.672 1.911a1 1 0 10-1.932.518l.259.966a1 1 0 001.932-.518l-.26-.966zM2.429 4.74a1 1 0 10-.517 1.932l.966.259a1 1 0 00.517-1.932l-.966-.26zm8.814-.569a1 1 0 00-1.415-1.414l-.707.707a1 1 0 101.415 1.415l.707-.708zm-7.071 7.072l.707-.707A1 1 0 003.465 9.12l-.708.707a1 1 0 001.415 1.415zm3.2-5.171a1 1 0 00-1.3 1.3l4 10a1 1 0 001.823.075l1.38-2.759 3.018 3.02a1 1 0 001.414-1.415l-3.019-3.02 2.76-1.379a1 1 0 00-.076-1.822l-10-4z" clip-rule="evenodd"></path></svg>',
      },
      {
        link: 'https://cloud.objex.tech/ai',
        icon: '<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M5 6h3a1 1 0 0 1 1 1v2a1 1 0 0 1 -1 1h-3"/><path d="M5 14h3a1 1 0 0 1 1 1v2a1 1 0 0 1 -1 1h-3"/><path d="M9 8h4a1 1 0 0 1 1 1v6a1 1 0 0 1 -1 1h-4"/><line x1="14" y1="12" x2="19" y2="12"/></svg>',
      },
      {
        link: 'https://cloud.objex.tech/logs',
        icon: '<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M3.5 5.5l1.5 1.5l2.5 -2.5"/><path d="M3.5 11.5l1.5 1.5l2.5 -2.5"/><path d="M3.5 17.5l1.5 1.5l2.5 -2.5"/><line x1="11" y1="6" x2="20" y2="6"/><line x1="11" y1="12" x2="20" y2="12"/><line x1="11" y1="18" x2="20" y2="18"/></svg>',
      },
      {
        link: 'https://cloud.objex.tech/monitoring',
        icon: '<svg class="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M3 5a2 2 0 012-2h10a2 2 0 012 2v8a2 2 0 01-2 2h-2.22l.123.489.804.804A1 1 0 0113 18H7a1 1 0 01-.707-1.707l.804-.804L7.22 15H5a2 2 0 01-2-2V5zm5.771 7H5V5h10v7H8.771z" clip-rule="evenodd"></path></svg>',
      },
    ];

    this.services = this.services.map((e, i) => ({
      ...e,
      icon: this.sanitizer.bypassSecurityTrustHtml(e.icon),
      title: 'services.services.' + i + '.title',
      description: 'services.services.' + i + '.description',
    }));
  }
}
