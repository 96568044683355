import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {RouterModule, Routes} from '@angular/router';
import {SectionsComponent} from './sections/sections.component';
import {CommonModule} from '@angular/common';
import {ServicesComponent} from './sections/services/services.component';
import {ContactsComponent} from './sections/contacts/contacts.component';
import {TeamsComponent} from './sections/team/teams.component';
import {ProjectsComponent} from './sections/projects/projects.component';
import {AboutusComponent} from './sections/aboutus/aboutus.component';
import {Page404Component} from './objex/page404/page404.component';
import {Page500Component} from './objex/page500/page500.component';
import {LoginComponent} from './objex/login/login.component';
import {QuicklinkModule, QuicklinkStrategy} from 'ngx-quicklink';
import {PrivacyComponent} from './objex/privacy/privacy.component';
import { MicroservicesComponent } from './sections/microservices/microservices.component';
import { CloudComponent } from './sections/cloud/cloud.component';
import { ApisComponent } from './sections/apis/apis.component';
import { EcommerceComponent } from './sections/ecommerce/ecommerce.component';

const routes: Routes = [
  {path: '', component: SectionsComponent},
  {path: 'services', component: ServicesComponent},
  {path: 'contact', component: ContactsComponent},
  {path: 'team', component: TeamsComponent},
  {path: 'projects', component: ProjectsComponent},
  {path: 'aboutus', component: AboutusComponent},
  {path: '500', component: Page500Component},
  {path: '404', component: Page404Component},
  {path: 'privacy', component: PrivacyComponent},
  {path: 'login', component: LoginComponent},
  {path: 'microservices', component: MicroservicesComponent},
  {path: 'cloud', component: CloudComponent},
  {path: 'apis', component: ApisComponent},
  {path: 'ecommerce', component: EcommerceComponent},
  {path: '**', component: Page404Component}];

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    QuicklinkModule,
    RouterModule.forRoot(routes, {
      useHash: true,
      preloadingStrategy: QuicklinkStrategy
    })
  ],
  exports: [],
})
export class AppRoutingModule {
}
