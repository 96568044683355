const knownErrorsMap = {
  'auth/internal-error': 'An internal server error occurred. Please try again later.',
  'auth/user-not-found': 'Invalid Email or Password',
};

export function extractGoogleApiError(e) {
  let error = e;
  if (!e) {
    return 'Ooops! Something went wrong.'
  }
  if (typeof e.toJSON === 'function') {
    error = e.toJSON();
  }
  if (typeof error.error === 'object') {
    error = error.error;
  }

  if (typeof e.message === 'string') {
    try {
      error = {...error, message: JSON.parse(e.message)};
      // eslint-disable-next-line no-empty
    } catch (_) {
    }
  }

  if (typeof error.message === 'string') {
    return error.message;
  }
  if (knownErrorsMap[error.code]) {
    return knownErrorsMap[error.code];
  }
}
