<div class="relative bg-gray-800 overflow-hidden" id="hero">
  <div class="hidden sm:block sm:absolute sm:inset-0">
    <svg
      class="absolute bottom-0 right-0 transform translate-x-1/2 mb-48 text-gray-700 lg:top-0 lg:mt-28 lg:mb-0 xl:transform-none xl:translate-x-0"
      width="364" height="384" viewBox="0 0 364 384" fill="none">
      <defs>
        <pattern id="eab71dd9-9d7a-47bd-8044-256344ee00d0" x="0" y="0" width="20" height="20"
                 patternUnits="userSpaceOnUse">
          <rect x="0" y="0" width="4" height="4" fill="currentColor"/>
        </pattern>
      </defs>
      <rect width="364" height="384" fill="url(#eab71dd9-9d7a-47bd-8044-256344ee00d0)"/>
    </svg>
  </div>
  <div class="relative pt-6 pb-12 sm:pb-32">
    <main class="mt-8 sm:mt-16 md:mt-20 lg:mt-24">
      <div class="mx-auto max-w-screen-xl">
        <div class="lg:grid lg:grid-cols-12 lg:gap-8">
          <div
            class="px-4 sm:px-6 sm:text-center md:max-w-2xl md:mx-auto lg:col-span-6 lg:text-left lg:flex lg:items-center">
            <div>
              <!-- <a href="https://objex.careers" class="inline-flex items-center text-white bg-gray-900 rounded-full p-1 pr-2 sm:text-base lg:text-sm xl:text-base hover:text-gray-200"> -->
                <!-- <span class="px-3 py-0.5 text-white text-xs font-semibold leading-5 uppercase tracking-wide bg-indigo-500 rounded-full">We're hiring</span> -->
                <!-- <span class="ml-4 text-sm">Visit our careers page</span> -->
                <!-- Heroicon name: chevron-right -->
                <!-- <svg class="ml-2 w-5 h-5 text-gray-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true"> -->
                  <!-- <path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd" /> -->
                <!-- </svg> -->
              <!-- </a> -->
              <h2
                class="mt-4 text-4xl tracking-tight leading-10 font-extrabold text-white sm:mt-5 sm:leading-none sm:text-6xl lg:mt-6 lg:text-5xl xl:text-6xl">
                {{ 'hero.title' | translate }}
              </h2>
              <p class="mt-3 text-base text-gray-300 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl">
                {{ 'hero.description' | translate }}
              </p>
              <p class="mt-8 text-sm text-white uppercase tracking-wide font-semibold sm:mt-10">Our Partners</p>
              <div class="mt-5 w-full sm:mx-auto sm:max-w-lg lg:ml-0">
                <div class="grid grid-cols-1 md:grid-cols-3 gap-x-4 gap-y-10">
                  <div class="flex px-1">
                    <img class="h-9 sm:h-10" src="assets/img/gcp.svg" alt="GCP">
                  </div> 
                  <div class="flex px-1">
                    <img class="h-9 sm:h-10" src="assets/img/azure.svg" alt="Azure">
                  </div>
                  <div class="flex px-1">
                    <img class="h-9 sm:h-10" src="assets/img/aws.svg" alt="AWS">
                  </div>

                  <!-- <div class="flex px-1">
                    <img class="h-9 sm:h-10" src="assets/img/contentful.svg" alt="Contentful">
                  </div> -->
                </div>
              </div>
            </div>
          </div>
          <div class="mt-12 sm:mt-16 lg:mt-0 lg:col-span-6">
            <div class="bg-white sm:max-w-md sm:w-full sm:mx-auto sm:rounded-lg sm:overflow-hidden relative">
              <form [formGroup]="form" (ngSubmit)="submit()" *ngIf="!submitted; else submittedTemplate"
                    class="px-4 py-8 sm:px-10">
                <h3 class="mb-6 text-lg leading-6 font-medium text-gray-900">
                  {{ 'contact-us.title' | translate }}
                </h3>

                <div class="absolute inset-x-0 top-0" *ngIf="loading">
                  <mat-progress-bar mode="indeterminate"></mat-progress-bar>
                </div>

                <mat-form-field appearance="outline" class="w-full">
                  <mat-label>{{ 'contact-us.form.name' | translate }}</mat-label>
                  <input matInput type="text" autocomplete="given-name" formControlName="name">
                  <mat-error *ngIf="form.controls.name.invalid && form.controls.name.hasError('required')">
                    {{ 'contact-us.form.name' | translate }} field is required
                  </mat-error>
                </mat-form-field>

                <mat-form-field appearance="outline" class="w-full">
                  <mat-label>{{ 'contact-us.form.email' | translate }}</mat-label>
                  <input matInput type="email" formControlName="email">
                  <mat-error *ngIf="form.controls.email.invalid && form.controls.email.hasError('required')">
                    {{ 'contact-us.form.email' | translate }} field is required
                  </mat-error>
                </mat-form-field>

                <mat-form-field appearance="outline" class="w-full">
                  <mat-label>{{ 'contact-us.form.message' | translate }}</mat-label>
                  <textarea rows="5" matInput formControlName="message"></textarea>
                  <mat-error *ngIf="form.controls.message.invalid && form.controls.message.hasError('required')">
                    {{ 'contact-us.form.message' | translate }} field is required
                  </mat-error>
                </mat-form-field>

                <div class="mt-2" *ngIf="error">
                  <mat-error class="text-sm">{{error}}</mat-error>
                </div>

                <div class="mt-5">
                  <span class="inline-flex rounded-md shadow-sm">
                    <button type="submit"
                            class="inline-flex justify-center py-3 px-6 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition duration-150 ease-in-out">
                        {{ 'contact-us.form.submit' | translate }}
                    </button>
                  </span>
                </div>
              </form>

              <ng-template #submittedTemplate>
                <div class="flex flex-col items-center justify-center h-full py-20">
                  <svg class="w-16 h-16 text-green-400" fill="currentColor" viewBox="0 0 20 20"
                       xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd"
                          d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                          clip-rule="evenodd"></path>
                  </svg>

                  <p class="mt-6 text-blue-gray-700 text-xl">
                    {{ 'contact-us.form.success' | translate }}
                  </p>
                </div>
              </ng-template>
              <div class="px-4 py-6 bg-gray-50 border-t-2 border-gray-200 sm:px-10" *ngIf="!submitted">
                <p class="text-xs leading-5 text-gray-500">
                  By filling your information, you agree to our
                  <span class="font-medium text-gray-900">Term of Service</span> and
                  <span class="font-medium text-gray-900">Privacy Police</span>.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</div>
