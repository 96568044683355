<div class="bg-gray-800" id="cloud">

  <!-- Jumbotron -->
  <div
  class="relative overflow-hidden rounded-lg bg-cover bg-no-repeat p-12 text-center" style="background-image: url('assets/banners/cloud.png'); height: 400px">
  <div
    class="absolute bottom-0 left-0 right-0 top-0 h-full w-full overflow-hidden bg-fixed"
    style="background-color: rgba(0, 0, 0, 0.6)">
    <div class="flex h-full items-center justify-center">
      <div class="text-white">
        <h2 class="mb-4 text-6xl font-extrabold tracking-tight"><span class="text-green-500">Cloud</span> Architecture Consulting</h2>
        <h4 class="mb-6 text-xl font-semibold">Unlock the Power of Cloud Computing</h4>
      </div>
    </div>
  </div>
  </div>
  <!-- Jumbotron -->

  <div class="mx-auto py-12 px-4 max-w-screen-xl sm:px-6 lg:px-8 lg:py-24">
    <div class="space-y-12">

      <p class="text-white">
        Are you ready to leverage the full potential of cloud computing for your business? Look no further than Objex Cloud Architecture Services. With our expertise in Amazon Web Services (AWS), Microsoft Azure, and Google Cloud, we offer comprehensive solutions tailored to meet your unique business needs. Whether you are a startup, a small business, or an enterprise, we have the knowledge and experience to guide you through the cloud transformation journey.    
      </p>


      <!-- <div class="bg-cover" style="background-image: url('assets/banners/microservices.webp'); height: 50%"></div> -->

      <h4 class="text-green-500 text-4xl">Why Choose Objex Cloud Architecture Services?</h4>
      <ul class="mb-8 space-y-4 text-left text-gray-100 dark:text-gray-300">
        <li class="flex items-center space-x-3">
          <!-- Icon -->
          <svg class="h-40 w-40 text-green-500"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <path d="M7 18a4.6 4.4 0 0 1 0 -9h0a5 4.5 0 0 1 11 2h1a3.5 3.5 0 0 1 0 7h-12" /></svg>
          <span class="text-2xl"><span class="text-green-500">Expertise Across Leading Cloud Providers:</span> We understand that different businesses have varying requirements and preferences when it comes to cloud platforms. That's why we specialize in AWS, Azure, and Google Cloud. Our team of certified architects is well-versed in the intricacies of each platform, enabling us to recommend and implement the best solutions for your specific needs.</span>
        </li>
        <li class="flex items-center space-x-3">
          <!-- Icon -->
          <svg class="h-40 w-40 text-green-500"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <path d="M15 4l6 2v5h-3v8a1 1 0 0 1 -1 1h-10a1 1 0 0 1 -1 -1v-8h-3v-5l6 -2a3 3 0 0 0 6 0" /></svg>
          <span class="text-2xl"><span class="text-green-500">Tailored Solutions:</span> At Objex, we believe in personalized services. We take the time to understand your business objectives, existing infrastructure, and growth plans. Based on this analysis, we design and deploy cloud architectures that optimize performance, scalability, security, and cost-efficiency. Our solutions are scalable, allowing you to adapt and evolve as your business grows. </span>
        </li>     
        <li class="flex items-center space-x-3">
          <!-- Icon -->
          <svg class="h-40 w-40 text-green-500"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <path d="M15 12h5a2 2 0 0 1 0 4h-15l-3 -6h3l2 2h3l-2 -7h3z" transform="rotate(-15 12 12) translate(0 -1)" />  <line x1="3" y1="21" x2="21" y2="21" /></svg>
          <span class="text-2xl"><span class="text-green-500">Seamless Migration and Integration:</span> Migrating your existing applications, data, and infrastructure to the cloud can be a complex process. With our expertise, we ensure a seamless transition to the cloud, minimizing downtime and disruptions. We also specialize in integrating cloud services with your existing on-premises systems, creating hybrid environments that combine the best of both worlds. </span>
        </li>    
        <li class="flex items-center space-x-3">
          <!-- Icon -->
          <svg class="h-40 w-40 text-green-500"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <rect x="5" y="11" width="14" height="10" rx="2" />  <circle cx="12" cy="16" r="1" />  <path d="M8 11v-4a4 4 0 0 1 8 0v4" /></svg>
          <span class="text-2xl"><span class="text-green-500">Robust Security and Compliance:</span> We understand that data security is a top priority for your business. Our cloud architecture services prioritize security at every level. We implement industry best practices to safeguard your data, networks, and applications. We also help you navigate compliance requirements, ensuring that your cloud infrastructure meets regulatory standards. </span>
        </li>    
        <li class="flex items-center space-x-3">
          <!-- Icon -->
          <svg class="h-40 w-40 text-green-500"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round">  <path d="M15.05 5A5 5 0 0 1 19 8.95M15.05 1A9 9 0 0 1 23 8.94m-1 7.98v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z" /></svg>
          <span class="text-2xl"><span class="text-green-500">Continuous Optimization and Support:</span> Cloud architecture is an ongoing process. We provide continuous monitoring, performance optimization, and cost management to ensure that your cloud environment remains efficient and cost-effective. Our support team is available round the clock to address any issues and provide timely assistance. </span>
        </li>    
      
      </ul>
      <p class="text-gray-500 text-4xl leading-7 ">Accelerate Your Cloud Transformation with Objex Cloud Architecture Services</p>

      <p class="text-green-500 text-4xl leading-7">Objex Cloud Architecture Services empowers businesses to harness the full potential of cloud computing. With our expertise in AWS, Azure, and Google Cloud, we deliver tailored solutions that drive innovation, scalability, and cost savings. Let us help you unlock the power of the cloud and take your business to new heights. Get in touch with us today to get started on your cloud transformation journey. </p>
    </div>
  </div>
</div>
