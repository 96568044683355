import {LOCALE_ID, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SectionsComponent} from './sections.component';
import {ServicesComponent} from './services/services.component';
import {TeamsComponent} from './team/teams.component';
import {TestimonialsComponent} from './testimonials/testimonials.component';
import {ContactsComponent} from './contacts/contacts.component';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {RouterModule} from '@angular/router';

import {AgmCoreModule} from '@agm/core';
import {ProjectsComponent} from './projects/projects.component';
import {TranslateLoader, TranslateModule, TranslateService} from '@ngx-translate/core';
import {HttpClient} from '@angular/common/http';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {AboutusComponent} from './aboutus/aboutus.component';
import {RECAPTCHA_V3_SITE_KEY, RecaptchaModule, RecaptchaV3Module} from 'ng-recaptcha';
import {FlexLayoutModule} from '@angular/flex-layout';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatButtonModule} from '@angular/material/button';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import { HeroComponent } from './hero/hero.component';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  imports: [
    CommonModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    AgmCoreModule.forRoot({
      apiKey: 'YOUR_KEY_HERE'
    }),
    RouterModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    RecaptchaModule,
    FlexLayoutModule,

    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatProgressBarModule,
    RecaptchaV3Module,
  ],
  declarations: [
    SectionsComponent,
    ServicesComponent,
    TeamsComponent,
    TestimonialsComponent,
    ContactsComponent,
    ProjectsComponent,
    AboutusComponent,
    HeroComponent
  ],
  exports: [
    SectionsComponent
  ],
  providers: [
    {
      provide: LOCALE_ID, useFactory: (translate: TranslateService) => {
        return translate.currentLang;
      },
      deps: [TranslateService]
    },
    {provide: RECAPTCHA_V3_SITE_KEY, useValue: '6LdKctwZAAAAADVoh13MJBtzXuKojpPHOva2As2b'},
  ]
})
export class SectionsModule {
}
