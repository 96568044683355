import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-aboutus',
  templateUrl: './aboutus.component.html',
  styleUrls: ['./aboutus.component.scss']
})
export class AboutusComponent implements OnInit {
    title = 'About Us';

    constructor(private titleService: Title, private metaService: Meta) { }

    ngOnInit() {
        this.titleService.setTitle(this.title);
        this.metaService.addTags([
          {name: 'keywords', content: 'Kubernetes, Cloud Native, APIs, Apigee, Microservices, Monitoring, GCP, Azure, AWS'},
          {name: 'description', content: 'Objex, Inc. | About'},
          {name: 'robots', content: 'index, follow'}
        ]);
      }
}
