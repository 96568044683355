import {Component, OnInit} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Meta, Title} from '@angular/platform-browser';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {environment} from '../../../environments/environment';
import {extractGoogleApiError} from '../utils';
import {ReCaptchaV3Service} from 'ng-recaptcha';

declare const $: any;

@Component({
  selector: 'app-contacts',
  templateUrl: './contacts.component.html',
  styleUrls: ['./contacts.component.scss']
})
export class ContactsComponent implements OnInit {
  form = new FormGroup({
    lastName: new FormControl('', [Validators.required]),
    firstName: new FormControl('', [Validators.required]),
    email: new FormControl('', [Validators.required, Validators.email]),
    phone: new FormControl('', [Validators.required]),
    message: new FormControl('', [Validators.required]),
  });

  error: string;
  loading = false;
  submitted = false;
  captchaResponse = '';

  constructor(
    private http: HttpClient,
    private titleService: Title,
    private metaService: Meta,
    private recaptchaV3Service: ReCaptchaV3Service,
  ) {
  }

  ngOnInit() {
    this.titleService.setTitle('Objex, Inc.');
    this.metaService.addTags([
      {name: 'keywords', content: 'Objex, Objex Inc, Kubernetes, Cloud Native, APIs, Apigee, Microservices, Monitoring, GCP, Azure, AWS'},
      {
        name: 'og:description', content: 'Contact us to provide expert ' +
          'guidance in migrating monolith or traditional apps to use microservice architecture.'
      },
      {name: 'robots', content: 'index, follow'}
    ]);
  }

  validateToken(token) {
    const options = {
      headers: new HttpHeaders({'Content-type': 'application/x-www-form-urlencoded'})
    };
    return this.http.post<any>(
      `${environment.functionBaseUrl}/validate-recaptcha`,
      {recaptcha: token},
      options
    ).toPromise();
  }

  async submit() {
    if (!this.form.valid) {
      return;
    }

    this.error = null;
    this.loading = true;

    try {
      const token = await this.recaptchaV3Service.execute('objex_contact_us').toPromise();
      await this.validateToken(token);

      await this.http.post(
        `${environment.functionBaseUrl}/hsform`,
        JSON.stringify(this.form.value),
        {
          params: this.form.value,
          responseType: 'text',
        },
      ).toPromise();

      this.submitted = true;
    } catch (e) {
      this.error = extractGoogleApiError(e);
    }

    this.loading = false;
  }

  resolved(captchaResponse: string) {
    this.captchaResponse = captchaResponse;
  }
}
