import { Component, OnInit, OnDestroy } from '@angular/core';

@Component({
  selector: 'app-privacy',
  templateUrl: './privacy.component.html',
  styleUrls: ['./privacy.component.scss']
})
export class PrivacyComponent implements OnInit, OnDestroy {

  constructor() { }

  ngOnInit() {}
  ngOnDestroy() {}

}
