<div class="section section-image lazyload" style="background-image: url('assets/img/sections/soroush-karimi.jpg'); color:white; font-size: large;" id="aboutus">
    <!-- banner -->
    <div class="page-header page-header-xs">
        <div class="filter filter-danger"></div>
        <div class="content-center">
            <div class="container">
                <h1>{{ "hello" | translate }}, <br /> {{ "we.are" | translate }} Objex, Inc.</h1>
                <h3>{{ "let.us.tell" | translate }}.</h3>
            </div>
        </div>
    </div>

    <div class="container">
        <h3 class="title-uppercase">{{ "digital.transformation.partners" | translate }}</h3>
        <p>{{ "digital.transformation.partners.line1" | translate }}.</p>

        <h3 class="title-uppercase">{{ "our.story" | translate }}</h3>
        <p>{{ "our.story.line1" | translate }} {{ "our.story.line2" | translate }}</p>
        <p>{{ "our.story.line3" | translate }}.</p>

        <h3 class="more-info">{{ "guiding.principles.title" | translate }}</h3>
        <br/>
        <div class="row coloured-cards">
            <div class="col-md-4 col-sm-6">
                    <div class="card card-just-text" data-background="color" data-color="blue" data-radius="none">
                        <div class="card-body">
                            <h4 class="card-title"><a href="javascript:void(0)">{{ "guiding.principles.principle1" | translate }}</a></h4>
                            <p class="card-description">{{ "guiding.principles.principle1.body" | translate }} </p>
                        </div>
                    </div> <!-- end card -->
            </div>

            <div class="col-md-4 col-sm-6">
                    <div class="card card-just-text" data-background="color" data-color="green" data-radius="none">
                        <div class="card-body">
                            <h4 class="card-title"><a href="javascript:void(0)">{{ "guiding.principles.principle2" | translate }}</a></h4>
                            <p class="card-description">{{ "guiding.principles.principle2.body" | translate }} </p>
                        </div>
                    </div> <!-- end card -->
            </div>

            <div class="col-md-4 col-sm-6">
                    <div class="card card-just-text" data-background="color" data-color="yellow" data-radius="none">
                        <div class="card-body">
                            <h4 class="card-title"><a href="javascript:void(0)">{{ "guiding.principles.principle3" | translate }}</a></h4>
                            <p class="card-description">{{ "guiding.principles.principle3.body" | translate }} </p>
                        </div>
                    </div> <!-- end card -->
            </div>
        </div>

    </div>
</div>


