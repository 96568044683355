<div class="bg-gray-800" id="microservices">

  <!-- Jumbotron -->
  <div
  class="relative overflow-hidden rounded-lg bg-cover bg-no-repeat p-12 text-center" style="background-image: url('assets/banners/ecommerce.jpG'); height: 400px">
  <div
    class="absolute bottom-0 left-0 right-0 top-0 h-full w-full overflow-hidden bg-fixed"
    style="background-color: rgba(0, 0, 0, 0.6)">
    <div class="flex h-full items-center justify-center">
      <div class="text-white">
        <h2 class="mb-4 text-6xl font-extrabold tracking-tight"><span class="text-red-500">mPOS/eCommerce</span> Architecture Consulting</h2>
        <h4 class="mb-6 text-xl font-semibold">Your Complete E-commerce and MPOS Architecture Service Provider</h4>

      </div>
    </div>
  </div>

  </div>
  <!-- Jumbotron -->

  <div class="mx-auto py-12 px-4 max-w-screen-xl sm:px-6 lg:px-8 lg:py-24">
    <div class="space-y-12">

      <p class="text-white">
        In today's fast-paced digital landscape, businesses need software solutions that are highly scalable, flexible, and efficient. Objex is proud to present our Microservices Architecture Service, designed to revolutionize the way you develop and deploy applications. With our cutting-edge technology and expertise, we empower you to build resilient and scalable systems that can adapt to your evolving business needs.      
      </p>


      <!-- <div class="bg-cover" style="background-image: url('assets/banners/microservices.webp'); height: 50%"></div> -->

      <h4 class="text-red-500 text-4xl">Key Features:</h4>
      <ul class="space-y-4 text-left text-gray-200 dark:text-gray-300">
        <li class="flex items-center">
          <span class="text-2xl"><span class="text-red-500">At Objex,</span> we understand the evolving needs of retail businesses in today's digital landscape. That's why we offer a comprehensive suite of services tailored to meet the unique requirements of retail giants like Lululemon and Aldo. With our expertise in UI, payments, loyalty, inventory, pricing, promotions, couponing, omni and unified commerce, we empower our retail customers to thrive in the competitive online marketplace.</span>
        </li>
        <li class="flex items-center space-x-3">
          <!-- Icon -->
          <svg class="text-red-500 w-40 h-40"  viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <rect x="7" y="4" width="10" height="16" rx="1" />  <line x1="11" y1="5" x2="13" y2="5" />  <line x1="12" y1="17" x2="12" y2="17.01" /></svg>
          <span class="text-2xl"><span class="text-red-500">Seamless User Interface (UI):</span>
            We believe that a well-designed user interface is key to providing an exceptional customer experience. Our team of skilled designers and developers create intuitive and visually appealing interfaces that enhance user engagement and drive conversions. Whether it's optimizing the checkout process or designing mobile-responsive layouts, we ensure that your customers enjoy a seamless journey from browsing to purchase. </span>
        </li>     
        <li class="flex items-center space-x-3">
          <!-- Icon -->
          <svg class="h-40 w-40 text-red-500" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <rect x="3" y="5" width="18" height="14" rx="3" />  <line x1="3" y1="10" x2="21" y2="10" />  <line x1="7" y1="15" x2="7.01" y2="15" />  <line x1="11" y1="15" x2="13" y2="15" /></svg>
          <span class="text-2xl"><span class="text-red-500">Efficient Payments Integration:</span>
            Objex takes the hassle out of payment integration. We offer seamless integration services with a wide range of payment gateways, allowing your customers to complete transactions quickly and securely. Our architecture supports various payment methods, including credit cards, mobile wallets, and digital currencies, enabling you to cater to diverse customer preferences and drive higher conversion rates. </span>
        </li>    
        <li class="flex items-center space-x-3">
          <!-- Icon -->
          <svg class="h-40 w-40 text-red-500"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <rect x="3" y="8" width="18" height="4" rx="1" />  <line x1="12" y1="8" x2="12" y2="21" />  <path d="M19 12v7a2 2 0 0 1 -2 2h-10a2 2 0 0 1 -2 -2v-7" />  <path d="M7.5 8a2.5 2.5 0 0 1 0 -5a4.8 8 0 0 1 4.5 5a4.8 8 0 0 1 4.5 -5a2.5 2.5 0 0 1 0 5" /></svg>
          <span class="text-2xl"><span class="text-red-500">Advanced Loyalty Programs:</span>
            We understand the importance of building strong customer relationships and fostering loyalty. Our robust services helps you design and implement tailored programs that reward your customers for their engagement and repeat purchases. By leveraging customer data and analytics, we help you personalize offers and promotions, boosting customer satisfaction and retention. </span>
        </li>    
        <li class="flex items-center space-x-3">
          <!-- Icon -->
          <svg class="h-40 w-40 text-red-500"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <circle cx="7" cy="17" r="2" />  <circle cx="17" cy="17" r="2" />  <path d="M5 17h-2v-11a1 1 0 0 1 1 -1h9v6h-5l2 2m0 -4l-2 2" />  <path d="M9 17h6" />  <path d="M13 6h5l3 5v6h-2" /></svg>
          <span class="text-2xl"><span class="text-red-500">Streamlined Inventory Management:</span>
            Managing inventory across multiple channels can be complex. With Objex, you gain experience of implementing sophisticated inventory management systems that enables real-time inventory tracking, synchronization, and optimization. This ensures that you never miss a sale due to stockouts while minimizing carrying costs. With our solution, you can streamline your supply chain and achieve better inventory visibility and control. </span>
        </li>    
        <li class="flex items-center space-x-3">
          <!-- Icon -->
          <svg class="h-40 w-40 text-red-500"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <line x1="9" y1="15" x2="15" y2="9" />  <circle cx="9.5" cy="9.5" r=".5" fill="currentColor" />  <circle cx="14.5" cy="14.5" r=".5" fill="currentColor" />  <path d="M5 7.2a2.2 2.2 0 0 1 2.2 -2.2h1a2.2 2.2 0 0 0 1.55 -.64l.7 -.7a2.2 2.2 0 0 1 3.12 0l.7 .7a2.2 2.2 0 0 0 1.55 .64h1a2.2 2.2 0 0 1 2.2 2.2v1a2.2 2.2 0 0 0 .64 1.55l.7 .7a2.2 2.2 0 0 1 0 3.12l-.7 .7a2.2 2.2 0 0 0 -.64 1.55 v1a2.2 2.2 0 0 1 -2.2 2.2h-1a2.2 2.2 0 0 0 -1.55 .64l-.7 .7a2.2 2.2 0 0 1 -3.12 0l-.7 -.7a2.2 2.2 0 0 0 -1.55 -.64h-1a2.2 2.2 0 0 1 -2.2 -2.2v-1a2.2 2.2 0 0 0 -.64 -1.55l-.7 -.7a2.2 2.2 0 0 1 0 -3.12l.7 -.7a2.2 2.2 0 0 0 .64 -1.55 v-1" /></svg>
          <span class="text-2xl"><span class="text-red-500">Dynamic Pricing and Promotions:</span>
            Competitive pricing and compelling promotions are crucial for driving sales and staying ahead of the competition. Our architecture equips you with dynamic pricing tools that allow you to adapt pricing strategies in real-time, ensuring optimal profitability while considering market conditions. Additionally, our promotions and couponing capabilities enable you to create targeted campaigns to attract and engage customers effectively. </span>
        </li>       
        <li class="flex items-center space-x-3">
          <!-- Icon -->
          <svg class="h-40 w-40 text-red-500"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <circle cx="5" cy="18" r="2" />  <circle cx="19" cy="6" r="2" />  <path d="M19 8v5a5 5 0 0 1 -5 5h-3l3 -3m0 6l-3 -3" />  <path d="M5 16v-5a5 5 0 0 1 5 -5h3l-3 -3m0 6l3 -3" /></svg>
          <span class="text-2xl"><span class="text-red-500">Omni and Unified Commerce:</span>
            In today's retail landscape, it's essential to provide a seamless shopping experience across all touchpoints. Objex enables you to achieve omni and unified commerce by integrating your online and offline channels. Whether it's a mobile app, website, or brick-and-mortar store, our architecture ensures consistent product information, inventory visibility, and customer data across all channels. This empowers you to deliver a unified brand experience and capitalize on opportunities for cross-channel sales and customer engagement. </span>
        </li>  
      </ul>
    
    </div>
    <br/>
    <div class="">
    <p class="text-red-500 text-4xl leading-7">With Objex, you gain a trusted partner that understands the complexities of e-commerce and MPOS architecture. We offer a comprehensive suite of services tailored to your specific retail needs, ensuring that you stay ahead in the competitive online marketplace. Partner with us today and unlock your retail business's full potential in the digital world. </p>
    </div>
  </div>
</div>
