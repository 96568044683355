<div class="my-20 text-center">
  <div class="my-8">
    <img class="center" src="../../assets/4042.png">
  </div>
  <div>
    <h3 class="text-gray-900 lg:text-6xl md:text-3xl sm:text-sm">{{"404.404" | translate}}</h3>
    <h1 class="text-gray-700">{{"404.message" | translate}}</h1>
  </div>

</div>
