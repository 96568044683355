<!--     *********    PROJECTS     *********      -->

<div class="section section-project cd-section projects-background" id="projects">
    <div class="bg-white">
        <div class="max-w-screen-xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
          <div class="lg:grid lg:grid-cols-2 lg:gap-8 lg:items-center">
            <div>
              <h2 class="text-3xl leading-9 font-extrabold text-gray-900 sm:text-4xl sm:leading-10">
                Customers we have recently provided Digital Transformation Services:
              </h2>
              <!-- <p class="mt-3 max-w-3xl text-lg leading-7 text-gray-500">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Et, egestas tempus tellus etiam sed. Quam a scelerisque amet ullamcorper eu enim et fermentum, augue.
              </p> -->
              <div class="mt-8 sm:flex">
                <div class="mt-3 sm:mt-0 sm:ml-3">
                    <!-- <span class="inline-flex rounded-md shadow-sm">
                        <a type="submit" href="#contactus"
                                class="inline-flex justify-center py-3 px-6 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition duration-150 ease-in-out">
                            Contact Us
                      </span> -->
                </div>
              </div>
            </div>
            <div class="mt-8 grid grid-cols-2 gap-0.5 md:grid-cols-3 lg:mt-0 lg:grid-cols-2">
              <div class="col-span-1 flex justify-center py-8 px-8 bg-gray-50">
                <img class="max-h-12" src="assets/img/projects/aldo.png" alt="Aldo">
              </div>
              <div class="col-span-1 flex justify-center py-8 px-8 bg-gray-50">
                <img class="max-h-12" src="assets/img/projects/lululemon.png" alt="Lululemon">
              </div>
              <div class="col-span-1 flex justify-center py-8 px-8 bg-gray-50">
                <img class="max-h-12" src="assets/img/projects/cn.png" alt="CN">
              </div>
              <div class="col-span-1 flex justify-center py-8 px-8 bg-gray-50">
                <img class="max-h-12" src="assets/img/projects/lightspeed.svg" alt="Lightspeed">
              </div>
              <div class="col-span-1 flex justify-center py-8 px-8 bg-gray-50">
                <img class="max-h-12" src="assets/img/projects/ludia.jpg" alt="Ludia">
              </div>
              <div class="col-span-1 flex justify-center py-8 px-8 bg-gray-50">
                <img class="max-h-12" src="assets/img/projects/morgan-stanley.svg" alt="Morgan Stanley">
              </div>
              <div class="col-span-1 flex justify-center py-8 px-8 bg-gray-50">
                <img class="max-h-12" src="assets/img/projects/mcdonalds.svg" alt="McDonalds">
              </div>
              <div class="col-span-1 flex justify-center py-8 px-8 bg-gray-50">
                <img class="max-h-12" src="assets/img/projects/ge-healthcare.png" alt="GE Healthcare">
              </div>
            </div>
          </div>
        </div>
      </div>

</div>
