import { Component, OnInit } from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {environment} from '../../../environments/environment';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {extractGoogleApiError} from '../utils';
import {ReCaptchaV3Service} from 'ng-recaptcha';

@Component({
  selector: 'app-hero',
  templateUrl: './hero.component.html',
  styleUrls: ['./hero.component.scss']
})
export class HeroComponent implements OnInit {
  form = new FormGroup({
    name: new FormControl('', [Validators.required]),
    email: new FormControl('', [Validators.required, Validators.email]),
    message: new FormControl('', [Validators.required]),
  });

  error: string;
  loading = false;
  submitted = false;

  constructor(
    private recaptchaV3Service: ReCaptchaV3Service,
    private http: HttpClient
  ) { }

  validateToken(token) {
    const options = {
      headers: new HttpHeaders({'Content-type': 'application/x-www-form-urlencoded'})
    };
    return this.http.post<any>(
      `${environment.functionBaseUrl}/validate-recaptcha`,
      {recaptcha: token},
      options
    ).toPromise();
  }

  ngOnInit(): void {
  }

  async submit() {
    if (!this.form.valid) {
      return;
    }

    this.error = null;
    this.loading = true;

    try {
      const token = await this.recaptchaV3Service.execute('objex_contact_us').toPromise();
      await this.validateToken(token);

      const formValue = {
        ...this.form.value,
        firstName: this.form.value.name.split(' ').slice(0, -1).join(' '),
        lastName: this.form.value.name.split(' ').slice(-1).join(' '),
      };
      await this.http.post(
        `${environment.functionBaseUrl}/hsform`,
        JSON.stringify(formValue),
        {
          params: formValue,
          responseType: 'text',
        },
      ).toPromise();
      this.submitted = true;
    } catch (e) {
      this.error = extractGoogleApiError(e);
    }

    this.loading = false;
  }
}
