import {Component, ElementRef, Inject, OnInit, PLATFORM_ID, Renderer2, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {Subscription} from 'rxjs/Subscription';
import 'rxjs/add/operator/filter';
import {Location, registerLocaleData} from '@angular/common';


import localeFr from '@angular/common/locales/fr';
import localeFrExtra from '@angular/common/locales/extra/fr';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  public currentLang: string;

  // tslint:disable-next-line:ban-types
  constructor(@Inject(PLATFORM_ID) private platform: Object, public translate: TranslateService) {
    registerLocaleData(localeFr, 'fr', localeFrExtra);
    translate.addLangs(['en', 'fr']);
    translate.setDefaultLang('en');
    this.currentLang = translate.getBrowserLang();
    if (this.currentLang) {
      translate.use(this.currentLang.match(/en|fr/) ? this.currentLang : 'en');
    }

    if (this.currentLang) {
      console.log('Current Language is:' + this.currentLang);
      this.translate.use(this.currentLang);
    } else {
      this.currentLang = 'en';
      this.translate.use(this.currentLang);
      console.log('Now Language is:' + this.translate.currentLang);
    }
  }


  ngOnInit() {
  }
}
