import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

    data: Date = new Date();

    constructor() { }

    ngOnInit() {
        // var body = document.getElementsByTagName('body')[0];
        // body.classList.add('full-screen');
        // body.classList.add('login');
        // var navbar = document.getElementsByTagName('nav')[0];
        // navbar.classList.add('navbar-transparent');
        // if (navbar.classList.contains('nav-up')) {
        //     navbar.classList.remove('nav-up');
        // }
    }


}
